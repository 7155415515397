import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LoadingButton from '@mui/lab/LoadingButton';
import { AuthHelper } from '../helpers/AuthHelper';
import 'typeface-titillium-web';
import {SPIDReactButton} from '@dej611/spid-react-button'

import '@dej611/spid-react-button/dist/index.css'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://m-a-s-e.it/">
        m-a-s-e.it
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.


export default function SignIn() {

  const [loading, setLoading] = React.useState(false)
  const [loginSplash, setLoadingSplash] = React.useState(1);

  const [user, setUser] = React.useState({
    email: "",
    password: ""
  })

  React.useEffect(() => {
    if(localStorage.getItem('user_bak')){
      window.location.href='/signup';
    }
    window.setTimeout(() => {
      setLoadingSplash(false);
    }, 2000)
  }, [])
  const signIn = async() => {
    setLoading(true);
    try{
      const helperInstance = AuthHelper.getInstance();
      const res = await helperInstance.login({Username: user.email, Password: user.password});
      if(res.status === 'error'){
        alert("E-mail o password errata");
        setLoading(false);
        return;

      }
      const _user = (await AuthHelper.getUserFromIdToken())?.UserAttributes;
      const getRole = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/role/${_user.filter(x => x.Name === 'sub')[0]?.Value}`)
      const role = await getRole.json();
      if(role && role.matricola){
        window.setTimeout(() => {window.location.href="/";}, 1000)
      }else{
        window.setTimeout(() => {window.location.href="/onboarding";}, 1000)
      }
      
    }catch(ex){
      alert('Errore server');
    }
  }
  return (
    <>
    {loginSplash ? 
      <div className="splash" style={{height: '100vh', width: '100vw', background: 'url(/images/loginSplash.jpeg', backgroundSize: 'cover', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: loginSplash}}>
        <img src="/images/New Logo MASE  TR2 mod.png" style={{position: 'relative', zIndex: 99, width: 300, objectFit: 'cover'}}/>
      </div>  
    : <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        <img src="/images/New Logo MASE  TR2 mod.png" width={125} height={125} alt="logo"/>

          <Typography component="h1" variant="h5">
            Accedi
          </Typography>
          <Box noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              onChange={(e) => setUser({...user, email: e.target.value})}              
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setUser({...user, password: e.target.value})}              
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Ricordami"
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}
              onClick={signIn}
            >
              Accedi
            </LoadingButton>
            <Grid container>
              <Grid item xs={12}>
                <Link href="/signup" variant="body2">
                  {"Non hai un account? Registrati!"}
                </Link>
              </Grid>
            </Grid>
          
            {/* <Grid container>
              <Grid item xs={12}><br />
                  <span>Oppure</span>
              </Grid>
              <Grid item xs={12}>
              <SPIDReactButton fluid={true} style={{margin: 0}} url="{{idp}}"/>
              </Grid>
            </Grid> */}
            <Grid container>
              <Grid item xs={12}>
                <Link href="/forgot" variant="body2">
                  {"Password dimenticata"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>}
    </>
      
  );
}